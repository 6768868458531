// Here you can add other styles

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
$columns: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1600px;
  }
}

@each $space in $spaceamounts {
  .height-#{$space}vh {
    height: #{$space}vh !important;
  }

  .width-#{$space}vw {
    width: #{$space}vw !important;
  }

  .height-#{$space}p {
    height: #{$space}% !important;
  }

  .width-#{$space}p {
    width: #{$space}% !important;
  }
}

@each $column in $columns {
  .col-#{$column} {
    width: #{$column * (100 / 12)}% !important;
  }
}

.h-100p {
  height: 100%;
}

.d-contents {
  display: contents;
}

.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-inline {
  display: inline-flex;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.flex-expand {
  flex: 1;
}

.full-width {
  width: 100% !important;
}

.custom-table .table-responsive {
  min-height: 300px;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  margin: 0px !important;
}

.text-bold {
  font-weight: bold;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-start {
  align-items: flex-start;
}

.sidebar {
  background: $d-primary-dark;
  &-status-header {
    font-weight: 500;
  }
  &-service-container {
    padding: 0px 20px;
    font-weight: normal;
  }
  &-margin-left {
    margin-left: 22px;
  }
  &-online {
    color: $success;
  }
  &-offline {
    color: $danger;
  }
  &-warning {
    color: $warning;
  }
  &-circle {
    &-online {
      background-color: $success;
      display: inline-block;
      margin-right: 12px;
      height: 10px;
      width: 10px;
      border-radius: 5px;
    }
    &-offline {
      background-color: $danger;
      display: inline-block;
      margin-right: 12px;
      height: 10px;
      width: 10px;
      border-radius: 5px;
    }
    &-warning {
      background-color: $warning;
      display: inline-block;
      margin-right: 12px;
      height: 10px;
      width: 10px;
      border-radius: 5px;
    }
  }
}

.sidebar-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-right {
  margin-right: -1000px;
}

.overflow-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//loading spinner custom
.spinner-custom {
  width: 40px;
  height: 40px;
  margin: 10px auto;
  background-color: $d-primary-darkest;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.loading-app,
.error-app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: #073765;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  color: #073765;
}

.fade-in-fade-out {
  opacity: 1;
  animation: fade 2s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.ok {
  background: #73d981;
}

.error {
  background: #d97373;
}

.gcard-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.custom-items-per-page-dropdown-menu {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  button {
    padding: 0 !important;
    color: black !important;
    font-weight: 600;
    background-color: inherit !important;
    border: none !important;
    box-shadow: none !important;
    display: flex;
    text-align: start;
    display: flex;
    align-items: center;

    .chevron-custom {
      display: flex;
      flex-direction: column;
      color: black;
      margin-left: 5px;

      svg {
        max-width: 10px;

        &:first-child {
          margin-bottom: -3px;
        }

        &:last-child {
          margin-top: -3px;
        }
      }
    }
  }

  &:hover,
  &:focus,
  &.active {
    button {
      color: black;
      .chevron-custom {
        color: black !important;
      }
    }
  }
}

.custom-filter-dropdown-item {
  padding: 0.25rem 0.25rem;
  //display: flex;
  flex-direction: row;
  display: flex;

  span {
    flex: 20%;
    display: flex;
  }

  :last-child {
    flex: 80%;
  }
}

.gdl-modal {
  .modal-content {
    margin: 0 -200px;
    width: 1300px;
  }

  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }

}

.documento-lavoro {
  display: flex;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
  font-size: 12px;

  .g-input-text {
    margin-bottom: 4px;
    justify-content: left;
    align-items: center;
    label {
      margin-right: 8px;
      margin-bottom: 0px;
      min-width: 10%;
      max-width: 40%;
    }
    .form-control {
      max-width: 80%;
      height: 30px;
      &:disabled {
        background: white;
        border: 0px solid;
      }
    }
  }

  .g-input-radio {
    label {
      margin-bottom: 0px;
      width: fit-content;
      max-width: max-content;
    }
    .input-label {
      width: fit-content;
      margin-right: 40px;
    }
    .form-control {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .header {
    background: #eeeeee;
    text-align: center;
    border-bottom: 1px solid black;
  }

  .intestazione {
    border: 1px solid blacK;
    display: flex;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
  }
}

.luogo {
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  border: 1px solid black;
  border-bottom: 0px;
  .contenitore-codice {
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    .left-column {
      font-size: 12px;
      border-right: 1px solid black;
    }
    .right-column {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .g-input-text {
      border-bottom: 0px;
      label {
        width: 30%;
      }
      .form-control {
        width: 70%;
      }
    }
  }
  .contenitore-informazioni {
    display: flex;
    flex-direction: column;
    .g-input-text {
      border-bottom: 1px solid black;
      margin-bottom: 0px;
      label {
        margin-left: 1%;
        width: 19%;
      }
      .form-control {
        width: 80%;
      }
    }
  }
}

.informazioni {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  .lavori {
    display: flex;
    border-bottom: 1px solid black;
    .container-radio {
      justify-content: center;
      border-right: 1px solid black;
    }
  }
  .tabella {
    display: flex;
    flex-direction: column;
    .descrizione {
      border-right: 1px solid black;
      p {
        margin-bottom: 2px;
        font-size: 10px;
      }
    }
  }
  .addetti {
    border-bottom: 1px solid black;
  }
}

.attivita,
.materiali {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-bottom: 0px solid black;
  .riga {
    border-bottom: 1px solid black;
    padding-left: 4px;
  }
}

.rilievo,
.allegati {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  .image-container {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.materiali {
  .columns {
    div {
      border-right: 1px solid black;
      text-align: center;
      font-size: 10px;
      border-bottom: 1px solid black;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .col-2:last-child {
      border-right: 0px;
    }
  }
}

.form-input {
  .g-input-text {
    label {
      width: 100%;
    }
    .form-control {
      width: 100%;
    }
  }
}

.g-input-text-hidden-label {
  label {
    display: none;
  }
  .form-control {
    width: 100%;
  }
}

.bordered {
  border: 1px solid black;
}

.threedots:after {
  content: "\2807";
  font-size: 24px;
  color: black;
}

//delete dropleft on left-side of threedots
.dropleft {
  button::before {
    content: none !important;
  }

  button {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.list-group-item {
  border: 0px solid !important;
}

.logo {
  width: 200px;
  height: 40px;
}


.form-modal {
  .modal-body {
    overflow-y: visible;
  }
}

.g-input-text-area {
  .form-control:disabled {
    background: white;
    border: 0px solid;
  }
}

.height-capped-modal {
  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }
}

.giornale-dei-lavori {
  display: flex;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
  font-size: 12px;

  .g-input-radio {
    label {
      margin-bottom: 0px;
      width: fit-content;
      max-width: max-content;
    }
    .input-label {
      width: fit-content;
      margin-right: 40px;
    }
    .form-control {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .g-input-text {
    .form-control:disabled {
      border: 0px;
      background: white;
    }
  }


  .intestazione {
    border: 1px solid blacK;
    display: flex;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      border-left: 1px solid black;
      border-right: 1px solid black;
      font-weight: bold;
    }
    .informazioni-azienda {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      p {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
    .informazioni-progressivo {
      font-weight: bold;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .header {
    background: #eeeeee;
    text-align: center;
    border-bottom: 1px solid black;
  }

  .luogo {
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
    flex-direction: column;
    border: 1px solid black;
    .contenitore-codice {
      border-bottom: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      div:first-of-type {
        font-size: 12px;
        border-right: 1px solid black;
        text-align: justify;
        font-weight: bold;
      }
      div:last-of-type {
        font-weight: bold;
      }
    }
    .contenitore-informazioni {
      display: flex;
      flex-direction: row;
      justify-content: center;
        p {
          margin-bottom: 4px;
        }
        .targhette {
          border-right: 1px solid black;
          font-weight: bold;
          p:first-child {
            margin: 14px 0px;
          }
        }
      }
      .g-input-text {
        border-bottom: 0px;
        margin-top: -12px;
        height: 60px;
      }
    }

  .tabella-addetti {
    .header {
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black
    }
    .content {
      display: flex;
      flex-direction: row;
      border: 1px solid black;
      border-top: 0px;
      .descrizione {
        border-right: 1px solid black;
        p:first-child {
          text-align: center;
          font-weight: normal;
        }
        p {
          font-weight: bold;
          text-align: left;
          font-size: 12px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .button-form {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .tabella-attivita {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .header {
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black
    }
    .content {
      border-right: 1px solid black;
      border-left: 1px solid black;
      div {
        border-bottom: 1px solid black;
        padding-left: 4px;
      }
    }
  }

  .tabella-materiali {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .header {
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black;
      display: flex;
      flex-direction: column;
      .titolo {
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid black;
      }
      .columns {
        display: flex;
        div {
          border-left: 1px solid black;
        }
        div:first-of-type {
          border-left: 0px;
        }
      }
    }
    .content {
      border-right: 1px solid black;
      border-left: 1px solid black;
      .riga {
        display: flex;
        border-bottom: 1px solid black;
        div {
          text-align: center;
          border-left: 1px solid black;
        }
        div:first-of-type {
          text-align: left;
          border-left: 0px;
        }
      }
    }
  }

  .tabella-note {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .header {
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black;
      display: flex;
      flex-direction: column;
      .titolo {
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid black;
      }
      .columns {
        display: flex;
        div {
          border-left: 1px solid black;
        }
        div:first-of-type {
          border-left: 0px;
        }
      }
    }
    .content {
      border-right: 1px solid black;
      border-left: 1px solid black;
      .riga {
        display: flex;
        border-bottom: 1px solid black;
        div {
          text-align: center;
          border-left: 1px solid black;
        }
        div:first-of-type {
          text-align: left;
          border-left: 0px;
        }
      }
    }
  }

  .tabella-mezzi {
    display: flex;
    flex-direction: column;
    .header {
      border: 1px solid black;
      div {
        border-left: 1px solid black;
      }
      div:first-child {
        border-left: 0px;
      }
    }
    .content {
      display: flex;
      border: 1px solid black;
      border-top: 0px;
      .descrizione {
        border-right: 1px solid black;
        p:first-child {
          text-align: center;
          font-weight: normal;
        }
        p {
          font-weight: bold;
          text-align: left;
          font-size: 12px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .informazioni {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-bottom: 0px;
    .lavori {
      display: flex;
      .container-radio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid black;
      }
      .container-datetime {
        display: flex;
        flex-direction: column;
        .date {
          display: flex;
          align-items: center;
          margin: 8px 0px;
          .label {
            font-weight: bold;
            margin-right: 8px;
            width: 100px;
          }
        }
        .container-time {
          display: flex;
          margin: 8px 0px;
          .time {
            display: flex;
            align-items: center;
            margin-right: 16px;
            .label {
              font-weight: bold;
              margin-right: 8px;
            }
          }
          .time:last-of-type {
            margin-right: 0px;
          }

        }
      }
    }
  }

  .tabella-mezzi, .tabella-addetti {
    .button-form {
      margin-bottom: 12px;
    }
  }

  .datetime-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      margin-bottom: 12px;
    }
  }

  .form-input {
    &, .addetti {
      .g-select {
        width: 100%;
        margin-bottom: 24px;
      }
      .g-input-radio {
        margin-bottom: 24px;
        .input-label {
          width: 30%;
          min-width: 30%;
        }
        .form-control {
          height: 20px;
          width: 50%;
        }
      }
    }
  }

  .informazioni-esito {
    .g-input-text {
      align-items: center;
      label {
        margin-right: 8px;
      }
    }
  }

  .contenitore-rilievo {
    margin-top: 12px;
    .header {
      border: 1px solid black;
    }
    .content {
      border: 1px solid black;
      border-top: 0px;
      padding: 8px;
      img {
        display: block;
        margin: 0 auto;
        width: 50%;
      }
      .bottoni-azioni {
        display: flex;
        justify-content: center;
        button {
          margin-top: 0px !important;
          margin-right: 8px;
          width: 80px;
        }
        label {
          margin-bottom: 0px;

        }
      }
    }
  }

  .tabella-firme {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .header {
      border: 1px solid black;
      .titolo {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid black;
      }
      .columns {
        display: flex;
        div {
          border-right: 1px solid black;
        }
        div:last-of-type {
          border-right: 0px;
        }
      }
    }
    .content {
      display: flex;
      border: 1px solid black;
      border-top: 0px;
      div {
        border-right: 1px solid black;
        padding: 4px 0px;
        p {
          margin-bottom: 0px;
        }
      }
      div:last-of-type {
        border-right: 0px;
      }
    }
  }

}

.error-fade {
  color: red;
  font-weight: bold;
  font-size: 10px;
}

.home-map-container {
  height: 500px;
  width: 70%;
  border-right: 1px solid #AAAAAA;
}

.home-maintainer-list {
  height: 500px;
  width: 28%;
  padding: 1%;
  header {
    font-size: 16px;
  }
  .mantainer-list-header {
    display: flex;
    border: 1px solid #AAAAAA;
    background: #EEEEEE;
    margin-bottom: 8px;
    padding: 4px 0px;
    font-weight: bold;
    div {
      display: flex;
      align-items: center;
    }
  }
  .list-group {
    border: 1px solid #AAAAAA;
    .list-group-item:first-of-type {
      border-top: 0px !important;
    }
    .list-group-item {
      border-top: 1px solid #AAAAAA !important;
      display: flex;
      padding: 4px 0px;
      &:hover {
        cursor: pointer;
        background: #DDDDDD;
      }
    }
  }
}

.transparent {
  opacity: 0.0;
}

.c-username {
  margin-left: 12px;
}

.c-caret {
  margin-left: 8px;
  margin-top: 4px;
}

.c-breadcrumb {
  color:  #333333 !important;
}

.g-select-table {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    margin-bottom: 8px;
    background: #EEEEEE;
    padding: 8px 0px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.5);
    div {
      font-weight: bold;
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    .option-row {
      display: flex;
      margin: 4px 0px;
      padding: 12px 0px;
      border-radius: 8px;
      border: 1px solid rgba(0,0,0,0.3);
      &.selected {
        background: #DDDDDD !important;
      }
      &:hover {
        background: #EEEEEE;
        cursor: pointer;
      }
    }
  }
}

.g-subcard {
  header {
    font-size: 16px;
  }
}

.hidden-input {
  display: none;
}

.g-input-file-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  background: #321fdb;
  width: 80px;
  color: white;
}

.description-box {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 16px 20px;
  margin: 0px 0px;
  max-height: 250px;
  overflow-y: auto;
}

.group-menu-item {
  .dropdown-item {
    padding: 0px 4px;
  }
  .list-group-item {
    padding-left: 0px;
  }
}

.home-map-center-button {
  position: absolute;
  bottom: 12px;
  left: 10px;
  z-index: 1000;
}

.home-map-legenda {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 1000;
  background: white;
  border: 1px solid #555555;
  border-radius: 10px;
  padding: 8px;
  img {
    margin-right: 24px;
    height: 24px;
  }
  a {
    font-size: 12px;
  }
  &:hover {
    opacity: 0.8;
  }
  hr {
    margin: 8px 0px;
  }
}

.g-input-text {
  .form-control {
    color: black;
    &:disabled {
      color: #3c4b64;
    }
  }
}

.g-badge {
  text-align: center;
  color: #EEEEEE;
  border-radius: 4px;
  padding: 2px 4px;
  &.redact {
    background: #034C8C;
  }
  &.working {
    background: #3299D9;
  }
  &.approval {
    background: #F29F05;
  }
  &.verification {
    background: #038C7F;
  }
  &.approval_gdl {
    background: #60371E;
  }
  &.closure {
    background: #A6341B;
  }
  &.closed {
    background: #F21D1D;
  }
}

.g-circle {
  display: inline-block;
  margin: 8px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 12px;
}

.state-colors {
  &-redact {
    background: #034C8C;
  }
  &-working {
    background: #3299D9;
  }
  &-approval {
    background: #F29F05;
  }
  &-verification {
    background: #038C7F;
  }
  &-approval_gdl {
    background: #60371E;
  }
  &-closure {
    background: #A6341B;
  }
  &-closed {
    background: #F21D1D;
  }
}

.borderless { border: 0px }

.g-text-filter, .g-select-filter, .g-date-filter, .g-custom-filter {
  display: flex;
  flex-direction: column;
  width: 19%;
  margin-right: 1%;
  margin-bottom: 8px;
  label {
    font-weight: bold;
  }
}

.g-custom-filter {
  width: 29%;
  margin-right: 1%;
  .value-label {
    margin-top: 4px;
  }
}

.g-select-filter {
  div[class*="ValueContainer"] {
    max-height: 35px;
    overflow-y: auto;
  }
}

.g-date-filter {
  width: 39%;
  margin-right: 1%;
  .daterange-container {
    display: flex;
    height: 35px;
    align-items: center;
  }
  .daterange-label {
    font-weight: bold;
    margin-right: 8px;
    &:last-of-type {
      margin-left: 8px;
    }
  }
}


.g-tablefilters-container {
  display: flex;
  padding: 0px;
  margin: 8px 0px;
  .g-subcard {
    width: 100%;
    flex-direction: column;
    .card-body {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .button-container {
    width: 100%;
  }
}

.gdl-modal.visible-dropdown {
  .modal-body {
    overflow-y: visible;
  }
}

.g-dropdown {
  .dropdown-item.disabled {
    background: rgba(0, 0, 0, 0.05);
  }
  &.disabled {
    opacity: 0.5;
    button:hover {
      cursor: default;
    }
  }
}

.chat-container {
  width: 100%;
  display: flex;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: 0px;
  height: 500px;
  .user-list {
    width: 30%;
    height: 100%;
    border-right: 1px solid #DDDDDD;
    ul {
      list-style: none;
      padding-left: 0px;
      li:first-of-type {
        border-top: 0px;
      }
      li:last-of-type {
        border-bottom: 1px solid #DDDDDD;
      }
      li {
        height: 50px;
        font-size: 14px;
        font-weight: bold;
        border-top: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        padding-left: 4px;
        .chat-circle {
          margin-right: 8px;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: #1565c0;
        }
        &.active {
          background: #1565c0 !important;
          color: white;
        }
        &:hover {
          background: #EEEEEE;
          cursor: pointer;
        }
        a:first-of-type {
          flex-grow: 1;
        }
      }
    }
  }

  .chat-box, .no-user-box {
    height: 100%;
    width: 70%;
  }

  .no-user-box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }


  .chat-box {
    .message-container, .input-container {
      width: 100%;
    }
    .message-container {
      height: 460px;
      background: rgba(200, 200, 200, 0.1);
      border-bottom: 1px solid #DDDDDD;
      overflow-y: scroll;
      display: flex;
      flex-direction: column-reverse;
      .message-row {
        width: 96%;
        padding: 4px 2%;
        display: flex;
        &.sent-message {
          flex-direction: row-reverse;
          div {
            background: #63a4ff;
            color: white;
            padding: 8px;
            border-radius: 8px;
            min-width: 50px;
          }
        }
        &.received-message {
          flex-direction: row;
          div {
            background: #BBBBBB;
            color: white;
          }
        }
        &.sent-message,&.received-message {
          div {
            padding: 8px;
            border-radius: 8px;
            min-width: 50px;
            max-width: 40%;
            small {
              float: right;
            }
          }
        }
      }
    }
    .input-container {
      height: 37px;
      background: rgba(0, 0, 0, 0);
      display: flex;
      input[type=text] {
        flex-grow: 1;
        height: 100%;
        border: 0px;
        padding-left: 8px;
        outline: none !important;
        &:active, &:focus, &:focus-visible {
          border: 0px;
        }
      }
      svg {
        height: 37px;
        width: 28px;
        cursor: pointer;
        color: #1565c0;
      }
      svg.disabled {
        color: #BBBBBB;
        cursor: auto;
      }
    }
  }

}

.c-sidebar-nav-link {
  .chat-circle {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: 55%;
    background: rgba( 255, 255, 255, 0.8);
  }
  &.c-active {
    .chat-circle {
      opacity: 0;
    }
  }
}

.table-checkbox {
  width: 20px;
}

.mantainer-marker {
  ul {
    margin-bottom: 0px;
    padding-left: 20px;
  }
  li {
    font-size: 11px;
  }
}

.analysis-range {
  width: 100px
}

 .height-capped-card {
   height: 300px;
   overflow-y: auto;
 }

 .consent-page {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   h2 {
     font-size: 24px;
   }
   .main-card {
     min-width: 300px;
     max-width: 90%;
     .card-body {
       max-height: 60vh;
       overflow-y: scroll;
   }
   }
 }

 .about-page {
   h3, h5 {
     margin: 0px;
   }
   hr {
     margin: 12px -1.25rem;
   }
   p {
     font-size: 16px;
     font-weight: bold;
    a {
      min-width: 190px;
      display: inline-block;
      &.right-anchor {
      margin-left: 120px;
      font-weight: normal;
      }
      &.clickable:hover {
        min-width: 0px;
        cursor: pointer;
        color: blue;
      }
    }
   }
 }

 .license-modal {
   .modal-body {
     max-height: 60vh;
     overflow-y: scroll;
     line-height: 16px;
     .license-container {
       div {
         font-size: 12px;
         line-height: 12px;
       }
     }
   }
 }
