// Variable overrides
$theme-colors: (
    "success": #37bfa7,
    "primary": #172b4d,
    "info": #387ac4,
    "warning": #f9b114,
    "danger": #e55353,
    "light": #f1f3f9,
);

$disabled: #b5bfcb;
$enabled: #2a2b36;
$success: #37bfa7;
$primary-light: #c9e2fe;
$lighter: #f7fafc;
$g-primary: #181924;
$g-info: #387ac4;

//New theme
$d-primary-darkest: #181924;
$d-primary-dark: #2a2b36;
$d-primary-light: rgb(64, 65, 74);
$d-primary-lightest: #56575f;

//
$d-button-group-dark: rgb(56, 59, 64);
$d-button-group-light: rgb(76, 79, 84);

$d-text-color-gray: white;
$d-separator-color-gray: rgba(255,255,255, 0.1);

// Utility colors
$transparent: rgba(0,0,0,0);
$dark-overlay: rgba(0,0,0,0.2);
$darker-overlay: rgba(0,0,0,0.3);
$light-overlay: rgba(100,100,100,0.2);
